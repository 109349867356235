import { qs, qsa } from "../utils/lib";
export default class switchCrypted {
  constructor(el) {
    this.prevpage = qs(".home").dataset.prevpage;
    this.isCrypted = true;
    this.elementsContainer = qsa(".home_grid-col1 .hide");
    this.logos = qsa(".navbar_logo-cont div");
    this.criptoEls = qsa(".js-cripto");
  }
  init() {
    this.handleCrypto();
    this.changeOnClick();
  }

  changeOnClick() {
    this.criptoEls.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (!e.target.classList.contains("prevent_change")) {
          this.isCrypted = !this.isCrypted;
          this.setCrypto();
          if (window.location.hash) {
            history.pushState("", document.title, window.location.pathname);
          } else {
            window.location.hash = "decrypted";
          }
        }
      });
    });
  }

  handleCrypto() {
    if (window.location.hash) {
      this.isCrypted = false;
    } else {
      this.isCrypted = true;
    }
    this.setCrypto();
  }

  setCrypto() {
    if (this.isCrypted) {
      this.logos[1].classList.add("hidden");
      this.logos[1].classList.remove("visible");

      this.logos[0].classList.add("visible");
      this.logos[0].classList.remove("hidden");

      this.elementsContainer.forEach((el) => el.classList.add("hide"));
      this.elementsContainer.forEach((el) => el.classList.remove("visible"));
    } else {
      this.logos[1].classList.add("visible");
      this.logos[1].classList.remove("hidden");

      this.logos[0].classList.add("hidden");
      this.logos[0].classList.remove("visible");

      this.elementsContainer.forEach((el) => el.classList.remove("hide"));
      this.elementsContainer.forEach((el) => el.classList.add("visible"));
    }
  }
}
